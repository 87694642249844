let CookieUtils = {

    init: function () {
        let $banner = $('#cookie-banner');
        let cookie = CookieUtils.getCookie('cookielaw_accepted');
        if (cookie) {
            $banner.hide();
        } else {
            $banner.slideDown();
        }
    },

    getCookie: function (cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    createCookie: function (name, value, days, path) {
        let date = new Date(), expires;
        if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        } else {
            expires = "";
        }
        if(!path){
            path = "/";
        }
        document.cookie = name + "=" + value + expires + "; path=" + path;
    },

    createCookielawCookie: function () {
        this.createCookie('cookielaw_accepted', '1', 10 * 365);
        jQuery('#cookie-banner').slideUp();
    }
};

$(document).ready(function () {
    //CookieUtils.init();
});
